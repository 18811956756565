import {
  call, takeLatest, all, put,
} from 'redux-saga/effects';
import api from 'services/api';
import { Types, Creators } from 'store/ducks/place/insert/placeInsert';
import { callApi } from 'store/sagas/auth';

function retira_acentos(palavra) {
  let com_acento = "áàãâäéèêëíìîïóòõôöúùûüçÁÀÃÂÄÉÈÊËÍÌÎÏÓÒÕÖÔÚÙÛÜÇ";
  let sem_acento = "aaaaaeeeeiiiiooooouuuucAAAAAEEEEIIIIOOOOOUUUUC";
  var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  let nova = "";
  for (let i = 0; i < palavra.length; i++) {
    if (!format.test(palavra.substr(i, 1))) {
      if (com_acento.search(palavra.substr(i, 1)) >= 0)
        nova += sem_acento.substr(com_acento.search(palavra.substr(i, 1)), 1);
      else nova += palavra.substr(i, 1);
    } else nova += palavra.substr(i, 1);
  }
  return nova;
}

function* getPlaceInsert({ payload, length }) {
  try {
    for (let item of payload.osm.node) {
      let nodeObject = item.$;
      let namePlace = item.tag
        ? item.tag.find((item2) => item2.$.k === "name")
        : null;
      let refPlace = item.tag
        ? item.tag.find((item2) => item2.$.k === "ref")
        : null;
      if (namePlace || refPlace) {
        let typeCategory = -1

        let typeCategories = [
          'shop', 'aeroway', 'amenity', 'office', 'tourism',
          'building', 'railway', 'waterway', 'water', 'natural', 'plant:source', 
          'plant:source', 'military', 'route', 'government', 'office', 'leisure',
          'landuse', 'highway', 'place'
        ]

        for (let i = 0; i < typeCategories.length; i++){
          if(typeCategory === -1){
            typeCategory = item.tag.findIndex(item2 => item2.$.k === typeCategories[i]);
          } else {
            break;
          }
        }

        let newObject = {
          wayId: item.$.id,
          name: namePlace ? namePlace.$.v : refPlace.$.v,
          lat: nodeObject.lat,
          lng: nodeObject.lon,
          type: {
            category: typeCategory !== -1 ? item.tag[typeCategory].$.k : null,
            subCategory: typeCategory !== -1 ? item.tag[typeCategory].$.v : null,
          },
        };

        let request = call(api.get, `https://nominatim.openstreetmap.org/search?q=${yield retira_acentos(newObject.name)}%20${newObject.lat}%20${newObject.lng}&format=json&addressdetails=1&limit=1`);

        let response = yield call(callApi, request);
        if (response.status === 401) throw response;
        let dataAddress = response.data
        if(dataAddress.length){
          dataAddress = dataAddress[0]
        newObject = {
          ...newObject,
          lat: dataAddress.lat ? dataAddress.lat : nodeObject.lat,
          lng: dataAddress.lon ? dataAddress.lon : nodeObject.lng,
          importance: dataAddress.importance ? dataAddress.importance : null,
          licence: dataAddress.licence ? dataAddress.licence : null,
          place_rank: dataAddress.place_rank ? dataAddress.place_rank : null,
          address: {
            city: dataAddress.address.city ? dataAddress.address.city 
            : (dataAddress.address.town ? dataAddress.address.town 
              : (dataAddress.address.village ? dataAddress.address.village : null)),
            country: dataAddress.address.country ? dataAddress.address.country : null,
            country_code: dataAddress.address.country_code ? dataAddress.address.country_code : null,
            municipality: dataAddress.address.municipality ? dataAddress.address.municipality : null,
            postcode: dataAddress.address.postcode ? dataAddress.address.postcode : null,
            region: dataAddress.address.region ? dataAddress.address.region : null,
            road: dataAddress.address.road ? dataAddress.address.road : null,
            state: dataAddress.address.state ? dataAddress.address.state : null,
            state_district: dataAddress.address.state_district ? dataAddress.address.state_district : null,
            suburb: dataAddress.address.suburb ? dataAddress.address.suburb : null
          }
        }
        } else {
          newObject = {
            ...newObject,
            importance: 0,
            licence: null,
            place_rank: 0,
            address: {
              city: null,
              country: null,
              country_code: null,
              municipality: null,
              postcode: null,
              region: null,
              road: null,
              state: null,
              state_district: null,
              suburb: null
            }
          }
        }
        let request2 = call(api.post, '/places/insert', {
          ...newObject,
        });
        let response2 = yield call(callApi, request2);
        if (response2.status === 401) throw response2;
        yield put(Creators.getPlaceInsertSuccess({ total: length }));
      }
    }
    yield put(Creators.getPlaceInsertFinish());
    for (let item of payload.osm.way) {
      let nodeObject = payload.osm.node.find(
        (item2) => item2.$.id === item.nd[item.nd.length - 1].$.ref
      ).$;
      let namePlace = item.tag
        ? item.tag.find((item2) => item2.$.k === "name")
        : null;
      let refPlace = item.tag
        ? item.tag.find((item2) => item2.$.k === "ref")
        : null;
      if (namePlace || refPlace) {
        let typeCategory = -1

        let typeCategories = [
          'shop', 'aeroway', 'amenity', 'office', 'tourism',
          'building', 'railway', 'waterway', 'water', 'natural', 'plant:source', 
          'plant:source', 'military', 'route', 'government', 'office', 'leisure',
          'landuse', 'highway', 'place'
        ]

        for (let i = 0; i < typeCategories.length; i++){
          if(typeCategory === -1){
            typeCategory = item.tag.findIndex(item2 => item2.$.k === typeCategories[i]);
          } else {
            break;
          }
        }

        let newObject = {
          wayId: item.$.id,
          name: namePlace ? namePlace.$.v : refPlace.$.v,
          lat: nodeObject.lat,
          lng: nodeObject.lon,
          type: {
            category: typeCategory !== -1 ? item.tag[typeCategory].$.k : null,
            subCategory: typeCategory !== -1 ? item.tag[typeCategory].$.v : null,
          },
        };

        let request = call(api.get, `https://nominatim.openstreetmap.org/search?q=${newObject.name}%20${newObject.lat}%20${newObject.lng}&format=json&addressdetails=1&limit=1`);

        let response = yield call(callApi, request);
        if (response.status === 401) throw response;
        let dataAddress = response.data
        if(dataAddress.length){
          dataAddress = dataAddress[0]
        newObject = {
          ...newObject,
          lat: dataAddress.lat ? dataAddress.lat : nodeObject.lat,
          lng: dataAddress.lon ? dataAddress.lon : nodeObject.lng,
          importance: dataAddress.importance ? dataAddress.importance : null,
          licence: dataAddress.licence ? dataAddress.licence : null,
          place_rank: dataAddress.place_rank ? dataAddress.place_rank : null,
          address: {
            city: dataAddress.address.city ? dataAddress.address.city 
            : (dataAddress.address.village ? dataAddress.address.village 
              : (dataAddress.address.town ? dataAddress.address.town : null)),
            country: dataAddress.address.country ? dataAddress.address.country : null,
            country_code: dataAddress.address.country_code ? dataAddress.address.country_code : null,
            municipality: dataAddress.address.municipality ? dataAddress.address.municipality : null,
            postcode: dataAddress.address.postcode ? dataAddress.address.postcode : null,
            region: dataAddress.address.region ? dataAddress.address.region : null,
            road: dataAddress.address.road ? dataAddress.address.road : null,
            state: dataAddress.address.state ? dataAddress.address.state : null,
            state_district: dataAddress.address.state_district ? dataAddress.address.state_district : null,
            suburb: dataAddress.address.suburb ? dataAddress.address.suburb : null
          }
        }
        } else {
          newObject = {
            ...newObject,
            importance: 0,
            licence: null,
            place_rank: 0,
            address: {
              city: null,
              country: null,
              country_code: null,
              municipality: null,
              postcode: null,
              region: null,
              road: null,
              state: null,
              state_district: null,
              suburb: null
            }
          }
        }
        let request2 = call(api.post, '/places/insert', {
          ...newObject,
        });
        let response2 = yield call(callApi, request2);
        if (response2.status === 401) throw response2;
        yield put(Creators.getPlaceInsertSuccess({ total: length }));
      }
    }
    yield put(Creators.getPlaceInsertFinish());
  } catch (err) {
    console.log(err)
    yield put(Creators.getPlaceInsertFailure('Erro ao tentar inserir na API'));
  }
}


export default function* postoInsertSaga() {
  yield all([
    takeLatest(Types.GET_INSERT_REQUEST, getPlaceInsert),
  ]);
}