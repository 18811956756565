/* eslint-disable no-undef */
import "./App.css";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ConnectedRouter } from "connected-react-router";
import configureStore, { history } from "store/index";
import App from "components/App";
import { Helmet } from "react-helmet-async";
import { useEffect } from "react";

const { persistor, store } = configureStore();

function ReactApp() {
  return (
    <div>
        {/* paste script code in between Tilde  */}
        <Provider store={store}>
        <PersistGate persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </div>
  );
}

export default ReactApp;
