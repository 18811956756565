// Lista todas as Lojas
export const Types = {
  GET_INSERT_REQUEST: "place-insert/GET_INSERT_REQUEST",
  GET_INSERT_SUCCESS: "place-insert/GET_INSERT_SUCCESS",
  GET_INSERT_FAILURE: "place-insert/GET_INSERT_FAILURE",
  ACTIVE_LOADING: "place-insert/ACTIVE_LOADING",
  GET_INSERT_FINISH: "place-insert/GET_INSERT_FINISH",
};

export const initialState = {
  count: 0,
  total: 0,
  placeInsertLoading: false,
  placeInsertError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.ACTIVE_LOADING:
      return {
        ...state,
        placeInsertLoading: true,
      };
    case Types.GET_INSERT_REQUEST:
      return {
        ...state,
        count: 0,
        total: 0,
        placeInsertError: null,
        placeInsertLoading: true,
      };
    case Types.GET_INSERT_SUCCESS:
      return {
        ...state,
        count: state.count + 1,
        total: action.payload.total,
        placeInsertError: null,
      };
    case Types.GET_INSERT_FAILURE:
      return {
        ...state,
        count: 0,
        total: 0,
        placeInsertLoading: false,
        placeInsertError: action.payload,
      };
    case Types.GET_INSERT_FINISH:
      return {
        ...state,
        count: 0,
        total: 0,
        placeInsertLoading: false,
        placeInsertError: null,
      };
    default:
      return state;
  }
};

export const Creators = {
  getActiveLoading: () => ({
    type: Types.ACTIVE_LOADING,
  }),
  getPlaceInsertRequest: (data, length) => ({
    type: Types.GET_INSERT_REQUEST,
    payload: data,
    length
  }),
  getPlaceInsertSuccess: ({ total }) => ({
    type: Types.GET_INSERT_SUCCESS,
    payload: { total },
  }),
  getPlaceInsertFailure: (error) => ({
    type: Types.GET_INSERT_FAILURE,
    payload: error,
  }),
  getPlaceInsertFinish: () => ({
    type: Types.GET_INSERT_FINISH,
  }),
};
