import * as React from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import DirectionsIcon from "@mui/icons-material/Directions";
import { Autocomplete, Box, Grid, Typography } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { debounce } from "@mui/material/utils";

export default function CustomizedInputBase({
  visibility,
  onChange,
  onClick,
  options = [],
}) {
  // console.log(options)
  const [inputValue, setInputValue] = React.useState("");

  function hideKeyboard(element) {
    var field = document.createElement("input");
    field.setAttribute("type", "text");
    document.body.appendChild(field);

    setTimeout(function () {
      field.focus();
      setTimeout(function () {
        field.setAttribute("style", "display:none;");
      }, 0);
    }, 0);
  }

  return (
    <Paper component="form" sx={{ display: "flex", alignItems: "center" }}>
      <Autocomplete
        freeSolo
        id="custom-input-demo"
        options={
          options /*{ id: 1, name: 'teste', lat: 0, lng: 0, address: { city: 'teste', road: 'teste', suburb: 'teste' } }*/
        }
        sx={{ ml: 1, flex: 1 }}
        getOptionLabel={(option) => option.name}
        onChange={(e, value) => {
          hideKeyboard(e);
          onClick(value);
        }}
        filterOptions={(options, state) => options}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
          onChange(newInputValue);
        }}
        renderInput={(params) => (
          <div ref={params.InputProps.ref}>
            <input
              {...params.inputProps}
              style={{
                width: "100%",
                height: "50px",
                border: "none",
                outline: "none",
                fontSize: '17px'
              }}
              placeholder="Busque ou marque o destino da rota"
            />
          </div>
        )}
        renderOption={(props, option) => {
          const matches = match(option.name, inputValue);

          const parts = parse(option.name, matches);

          return (
            <li {...props} className="itemPlaces" key={option.wayId}>
              <Grid container alignItems="center" padding={'15px'}>
                <Grid item sx={{ display: "flex", width: 44 }}>
                  <LocationOnIcon sx={{ color: "text.secondary" }} />
                </Grid>
                <Grid
                  item
                  sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
                >
                  {parts.map((part, index) => (
                    <Box
                      key={index}
                      component="span"
                      sx={{ fontWeight: part.highlight ? "bold" : "regular" }}
                    >
                      {part.text}
                    </Box>
                  ))}
                  <Typography variant="body2" color="text.secondary">
                    {option.address.road ? `${option.address.road}` : ``}
                    {option.address.suburb ? `, ${option.address.suburb}` : ``}
                    {option.address.city ? `, ${option.address.city}` : ``}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
      <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
        <SearchIcon />
      </IconButton>
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <IconButton color="primary" sx={{ p: "10px" }} aria-label="directions">
        <DirectionsIcon />
      </IconButton>
    </Paper>
  );
}
