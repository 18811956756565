export const Types = {
    // Obtem postos
    GET_LIST_REQUEST: 'posto/GET_LIST_REQUEST',
    GET_CURRENT_COORDS_ROUTE: 'posto/GET_CURRENT_COORDS_ROUTE',
    GET_LIST_ROUTE_REQUEST: 'posto/GET_LIST_ROUTE_REQUEST',
    GET_LIST_SUCCESS: 'posto/GET_LIST_SUCCESS',
    GET_LIST_FAILURE: 'posto/GET_LIST_FAILURE',


  };

  export const initialState = {
    // Lista de postos
    postoList: [],
    postoListLoading: false,
    postoListError: null,
  };

  // eslint-disable-next-line import/no-anonymous-default-export
  export default (state = initialState, action) => {
    switch (action.type) {
      // Lista de postos
      case Types.GET_LIST_REQUEST || Types.GET_LIST_ROUTE_REQUEST:
        return { ...state, postoListLoading: true };
      case Types.GET_LIST_SUCCESS:
        return {
          ...state,
          postoList: action.payload,
          postoListLoading: false,
          postoListError: null,
        };
      case Types.GET_LIST_FAILURE:
        return {
          ...state,
          postoListLoading: false,
          postoListError: action.payload,
        };
      default:
        return state;
    }
  };

  export const Creators = {
    // Busca lista de postos
    getPostoListRouteRequest: (coords) => ({
      type: Types.GET_LIST_ROUTE_REQUEST,
      payload: { coords },
    }),
    getPostoListRequest: ({ lat, lng, cidade }, combustivel) => ({
      type: Types.GET_LIST_REQUEST,
      payload: { lat, lng, cidade, combustivel },
    }),
    getPostoListSuccess: ({ postos }) => ({
      type: Types.GET_LIST_SUCCESS,
      payload: postos,
    }),
    getPostoListFailure: error => ({
      type: Types.GET_LIST_FAILURE,
      payload: error,
    }),

  };
