export const Types = {
    // Obtem places
    GET_LIST_REQUEST: 'place/GET_LIST_REQUEST',
    GET_CURRENT_COORDS_ROUTE: 'place/GET_CURRENT_COORDS_ROUTE',
    GET_LIST_SUCCESS: 'place/GET_LIST_SUCCESS',
    GET_LIST_FAILURE: 'place/GET_LIST_FAILURE',
    
  
  };
  
  export const initialState = {
    // places list
    placeList: [],
    placeListLoading: false,
    placeListError: null,
  };
  
  // eslint-disable-next-line import/no-anonymous-default-export
  export default (state = initialState, action) => {
    switch (action.type) {
      // places list
      case Types.GET_LIST_REQUEST:
        return { ...state, placeListLoading: true };
      case Types.GET_LIST_SUCCESS:
        return {
          ...state,
          placeList: action.payload,
          placeListLoading: false,
          placeListError: null,
        };
      case Types.GET_LIST_FAILURE:
        return {
          ...state,
          placeListLoading: false,
          placeListError: action.payload,
        };
      default:
        return state;
    }
  };
  
  export const Creators = {
    // search places list
    getPlaceListRequest: (search) => ({
      type: Types.GET_LIST_REQUEST,
      payload: { search },
    }),
    getPlaceListSuccess: ({ places }) => ({
      type: Types.GET_LIST_SUCCESS,
      payload: places,
    }),
    getPlaceListFailure: error => ({
      type: Types.GET_LIST_FAILURE,
      payload: error,
    }),
  
  };
  