import { BrowserRouter, /*Redirect,*/ Route, Routes } from "react-router-dom";
// import { useSelector } from 'react-redux';
import Home from "pages/Home";

const App = () => {
  // const { isAuth, error } = useSelector(state => state.auth);

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
