import { DirectionsCar } from "@mui/icons-material";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Divider,
  Link,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import { green, grey, orange, yellow } from "@mui/material/colors";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import LogoGoodCard from "images/logo_good_card.png";
import LogoPetrobras from "images/brand_petrobras.png";
import LogoIpiranga from "images/brand_ipiranga.webp";
import LogoShell from "images/brand_shell.png";
import LogoAle from "images/brand_ale.png";
import React from "react";

const ItemPosto = ({
  dados,
  lat,
  lng,
  destinyDetails,
  indexItem,
  lastItem,
  currentCombustivel,
}) => {
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 820,
        md: 1280,
        lg: 1920,
        xl: 2560,
      },
    },
  });

  function addDays (days) {
    var date = new Date(dados.ultima_atualizacao);
    date.setDate(date.getDate() + days);
    return date;
  }

  return (
    <ThemeProvider theme={theme}>
      <Card
        sx={{
          mb: "30px",
          width: { sm: "46%", md: "30%", lg: "23%", xl: "15%" },
          marginLeft: { sm: "20px", md: "30px" },
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            padding: "10px",
            color: "white",
            fontWeight: "bold",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
            backgroundColor: "#81CC2B",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexGrow: 0,
          }}
        >
          <Typography sx={{ textAlign: "center", marginRight: '10px' }}>
            {dados.nome_fantasia ? dados.nome_fantasia : dados.razao_social}
          </Typography>
          {dados.brand === 'petrobras' && <img src={LogoPetrobras} width={'6%'} />}
          {dados.brand === 'ipiranga' && <img src={LogoIpiranga} width={'6%'} />}
          {dados.brand === 'shell' && <img src={LogoShell} width={'6%'} />}
          {dados.brand === 'ale' && <img src={LogoAle} width={'15%'} />}
        </Box>
        <Box
          sx={{
            width: "100%",
            pb: "8px",
            display: indexItem === 0 ? "flex" : "none",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              background: "black",
              color: "white",
              py: "3px",
              px: "8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography sx={{ fontSize: "12px", marginRight: "5px" }}>
              Economize R${" "}
              {String(
                (
                  lastItem[currentCombustivel] - dados[currentCombustivel]
                ).toFixed(2)
              ).replace(".", ",")}{" "}
              por litro
            </Typography>
            <MonetizationOnIcon fontSize="10px" sx={{ color: yellow["700"] }} />
          </Box>
        </Box>
        <CardContent
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: "15px",
            display: "flex",
            flexGrow: 1,
            flexBasis: "auto",
            flexWrap: "wrap",
            paddingTop: indexItem === 0 ? "0px" : "10px",
          }}
        >
          <Box sx={{ whiteSpace: "nowrap", width: "50%" }}>
            <Typography>
              Etanol: R${" "}
              {dados.etanol_comum === 0
                ? "--"
                : String(dados.etanol_comum).replace(".", ",")}
            </Typography>
          </Box>
          <Box sx={{ whiteSpace: "nowrap", width: "50%", textAlign: "end" }}>
            <Typography>
              Gasolina: R${" "}
              {dados.gasolina_comum === 0
                ? "--"
                : String(dados.gasolina_comum).replace(".", ",")}
            </Typography>
          </Box>
          <br />
          <Box sx={{ whiteSpace: "nowrap", width: "50%" }}>
            <Typography>
              Diesel: R${" "}
              {dados.diesel_comum === 0
                ? "--"
                : String(dados.diesel_comum).replace(".", ",")}
            </Typography>
          </Box>
          <Box sx={{ whiteSpace: "nowrap", width: "50%", textAlign: "end" }}>
            <Typography>
              Diesel-S10: R${" "}
              {dados.diesel_s10_comum === 0
                ? "--"
                : String(dados.diesel_s10_comum).replace(".", ",")}
            </Typography>
          </Box>
          <br />
          <br />
          <Box sx={{ display: 'flex', flexDirection: "column", width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
              style={{ float: "right" }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "left",
                  justifyContent: "end",
                }}
              >
                <Typography sx={{ fontSize: "12px" }}>
                  {dados.address.district}, {dados.address.city}
                </Typography>
                <Typography sx={{ fontSize: "12px" }}>
                  {dados.address.street.slice(0, 30)}
                  {dados.address.street.length > 30 ? "..." : ""},{" "}
                  {dados.address.number}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: !dados.notShowRadius ? "flex" : "none",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <Typography sx={{ marginLeft: "10px" }}>
                  ±{dados.rota.distance.text}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: dados.notShowRadius ? "flex" : "none",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <Typography
                  sx={{
                    marginLeft: "10px",
                    textAlign: "end",
                    fontSize: "12px",
                  }}
                >
                  Fora de Alcance
                </Typography>
              </Box>
            </Box>
            <Box>
            { dados.goodcard && <Box sx={{
              display: 'flex',
              background: 'orange',
              padding: '5px',
              alignItems: 'center',
              borderRadius: '5px',
              color: 'black',
              marginTop: '10px'
            }}>
              <img src={LogoGoodCard} width={'10%'} />
              <Typography sx={{ marginLeft: '10px', fontSize: '12px', fontWeight: 'bold' }}>
                Aceita Good Card
              </Typography>
              </Box>
            }
            </Box>
          </Box>
          { addDays(7) >= new Date() ? <Box sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'end'
          }}>
            <Box sx={{
              display: 'flex',
              background: green[500],
              padding: '5px',
              borderRadius: '100px',
              color: 'white',
              marginTop: '10px',
              paddingX: '10px'
            }}>
              Atualizado
            </Box>
        </Box>
        : <Box sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'end'
          }}>
            <Box sx={{
              display: 'flex',
              background: grey[500],
              padding: '5px',
              borderRadius: '100px',
              color: 'white',
              marginTop: '10px',
              paddingX: '10px'
            }}>
              Desatualizado
            </Box>
        </Box>
        }
        </CardContent>
        <Divider light />
        <CardActions
          sx={{
            flexGrow: 0,
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: orange[500],
            height: "50px",
          }}
        >
          {/* colocar endereço por extenso com nome do posto e cep*/}
          <Link
            href={
              dados.googleMapsUri
                ? dados.googleMapsUri
                : decodeURI(
                    `https://www.google.com/maps/dir/?api=1${
                      destinyDetails ? `&waypoints=` : `&destination=`
                    }${
                      dados.nome_fantasia
                        ? `POSTO COMBUSTIVEL ${dados.nome_fantasia
                            .replace("AUTO POSTO", "")
                            .replace("POSTO", "")}`
                        : `POSTO COMBUSTIVEL ${dados.razao_social
                            .replace("AUTO POSTO", "")
                            .replace("POSTO", "")}`
                    }${
                      dados.address?.street ? `, ${dados.address.street}` : ``
                    }${
                      dados.address?.number ? `, ${dados.address.number}` : ``
                    }${
                      dados.address?.district
                        ? `, ${dados.address.district}`
                        : ``
                    }${dados.address?.city ? `, ${dados.address.city}` : ``}${
                      dados.address?.state ? `, ${dados.address.state}` : ``
                    }${dados.address?.zip ? `, ${dados.address.zip}` : ``}${
                      dados.address?.country?.name
                        ? `, ${dados.address.country.name}`
                        : ``
                    }${
                      destinyDetails
                        ? `&destination=${destinyDetails?.lat}, ${destinyDetails?.lng}`
                        : ``
                    }`
                  )
            }
            target="_blank"
            underline="none"
            color="inherit"
          >
            <Typography fontWeight="bold" sx={{ marginRight: "0px" }}>
              Iniciar Rota
            </Typography>
          </Link>
          <DirectionsCar sx={{ marginTop: "-5px" }} />
        </CardActions>
      </Card>
    </ThemeProvider>
  );
};

export default ItemPosto;
