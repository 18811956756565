import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import LogoPostosGo from "images/logo_postosgo.png";

export default function DenseAppBar() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar variant="dense">
          <Box sx={{ mx: "auto", height: '4vh', py: '10px' }}>
              <img src={LogoPostosGo} height={'100%'} />
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
