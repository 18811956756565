import { call, takeLatest, all, put, debounce } from "redux-saga/effects";
import api from "services/api";

import { Types, Creators } from "store/ducks/place/list/placesList";

function retira_acentos(palavra) {
  let com_acento = "áàãâäéèêëíìîïóòõôöúùûüçÁÀÃÂÄÉÈÊËÍÌÎÏÓÒÕÖÔÚÙÛÜÇ";
  let sem_acento = "aaaaaeeeeiiiiooooouuuucAAAAAEEEEIIIIOOOOOUUUUC";
  var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  let nova = "";
  for (let i = 0; i < palavra.length; i++) {
    if (!format.test(palavra.substr(i, 1))) {
      if (com_acento.search(palavra.substr(i, 1)) >= 0)
        nova += sem_acento.substr(com_acento.search(palavra.substr(i, 1)), 1);
      else nova += palavra.substr(i, 1);
    } else nova += palavra.substr(i, 1);
  }
  return nova;
}

function* getPlaceList({ payload }) {
  try {
    const { search } = payload;
    if (search !== "") {
      const response = yield call(
        api.get,
        `/places/list/${yield retira_acentos(search)}/geolocal`
      );
      if (response.status !== 200) throw response;
      yield put(Creators.getPlaceListSuccess(response.data));
    } else {
      yield put(Creators.getPlaceListSuccess([]));
    }
  } catch (err) {
    yield put(Creators.getPlaceListFailure(err.data.mensagem));
  }
}

// Individual exports for testing
export default function* placeListSaga() {
  yield all([debounce(1000, Types.GET_LIST_REQUEST, getPlaceList)]);
}
